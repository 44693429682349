import React, { Dispatch, SetStateAction, createContext, useEffect, useRef, useState } from "react"
import { KEY_PATHS, KEY_TOKEN } from "@/constants"
import { unregisterServiceWorker } from "@/serviceWorker/serviceWorkerRegistration"

type LoginProps = {
  user: User
  token: string
}

export interface TPermissions {
  action?: string
  path: string
  name?: string
  description?: string
  can: string[]
  id?: number | string
}

type Action = "INSERT" | "UPDATE" | "DELETE" | "SHOW" | "LIST"

interface ICan {
  children?: React.ReactNode
  path: string
  can: Action | Action[]
}

interface ICanFunction {
  path: string
  can: Action | Action[]
}

interface IAuthenticateContext {
  isLoggedIn: boolean
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>
  setAvailableMenus: Dispatch<SetStateAction<string[]>>
  logout: () => void
  canShowSubmenu: (uri: string[]) => boolean
  hasPermissionList: (uri: string) => boolean
  login: (props: LoginProps) => void
  permissions: TPermissions[]
  availableMenus: string[]
  Auth: User
  Can: ({ children, path, can }: ICan) => React.ReactNode | null
  CanSome: ({ children, path, can }: ICan) => React.ReactNode | null
  can: ({ path, can }: ICanFunction) => boolean
  getAvailableMenus: () => string[]
  setMenu: Dispatch<SetStateAction<User["menu_parents"]>>
  menu: User["menu_parents"]
  routesWithPermissionsCreated: React.MutableRefObject<TPermissions[]>
  getDataOfPage: (path: string) => TPermissions | undefined
}

export const AuthenticationContext = createContext<IAuthenticateContext | null>(null)

export const getUserFromLocalStorage = () => {
  try {
    const user = localStorage.getItem("user")
    if (!user) return
    return JSON.parse(user)
  } catch (error) {
    localStorage.removeItem("user")
    localStorage.removeItem(KEY_TOKEN)
    localStorage.removeItem(KEY_PATHS)
    return
  }
}

const permissions = [
  {
    id: 5006,
    name: "INSERT",
    menu_id: 4,
    company_id: 30,
    user_id: 51,
    created_at: "2024-09-05T15:20:24.000000Z",
    updated_at: "2024-09-05T15:20:24.000000Z",
  },
  {
    id: 5007,
    name: "UPDATE",
    menu_id: 4,
    company_id: 30,
    user_id: 51,
    created_at: "2024-09-05T15:20:24.000000Z",
    updated_at: "2024-09-05T15:20:24.000000Z",
  },
  {
    id: 5008,
    name: "DELETE",
    menu_id: 4,
    company_id: 30,
    user_id: 51,
    created_at: "2024-09-05T15:20:24.000000Z",
    updated_at: "2024-09-05T15:20:24.000000Z",
  },
  {
    id: 5009,
    name: "SHOW",
    menu_id: 4,
    company_id: 30,
    user_id: 51,
    created_at: "2024-09-05T15:20:24.000000Z",
    updated_at: "2024-09-05T15:20:24.000000Z",
  },
  {
    id: 5010,
    name: "LIST",
    menu_id: 4,
    company_id: 30,
    user_id: 51,
    created_at: "2024-09-05T15:20:24.000000Z",
    updated_at: "2024-09-05T15:20:24.000000Z",
  },
]

const gotoMenuParents = [
  {
    id: 9998,
    name: "LTL Miles",
    description: "Manage your LTL Miles",
    url: "ltl-miles",
    created_at: "2024-04-30T21:00:48.000000Z",
    updated_at: "2024-04-30T21:00:48.000000Z",
    super_admin: false,
    menu_type: "menu_url",
    parent_id: 0,
    order: 0,
    permissions: permissions,
  },
  {
    id: 9999,
    name: "Driver Pay",
    description: "Manage your Driver Pay",
    url: "driver-pay",
    created_at: "2024-04-30T21:00:48.000000Z",
    updated_at: "2024-04-30T21:00:48.000000Z",
    super_admin: false,
    menu_type: "menu_url",
    parent_id: 0,
    order: 0,
    permissions: permissions,
  },
]

const AuthenticationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [permissions, setPermissions] = useState<TPermissions[]>(() => getPermissions())
  const [availableMenus, setAvailableMenus] = useState<string[]>([])
  const [menu, setMenu] = useState<User["menu_parents"]>([])
  const routesWithPermissionsCreated = useRef<TPermissions[]>(getPermissions())

  const [Auth, setAuth] = useState(() => getUserFromLocalStorage())
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    try {
      const token = localStorage.getItem(KEY_TOKEN)
      return !!token
    } catch (error) {
      localStorage.removeItem("user")
      localStorage.removeItem(KEY_TOKEN)
      localStorage.removeItem(KEY_PATHS)
      return false
    }
  })

  const getDataOfPage = (path: string): TPermissions | undefined => {
    return routesWithPermissionsCreated.current.find((c) => c.path?.endsWith(path))
  }

  const logout = () => {
    unregisterServiceWorker()
    localStorage.clear()
    setIsLoggedIn(false)
    setAvailableMenus([])
    setMenu([])
    setPermissions([])
    routesWithPermissionsCreated.current = []
  }

  const Can = ({ children, path, can }: ICan): React.ReactNode | null => {
    const permission = permissions.find((c) => c.path === path)
    if (!permission) return null
    if (typeof can === "string") {
      if (!permission.can?.includes(can)) return null
    } else {
      let hasPermission = true
      for (const _can of can) {
        if (!permission.can?.includes(_can)) {
          hasPermission = false
          break
        }
      }
      if (!hasPermission) return null
    }
    return children
  }

  const CanSome = ({ children, path, can }: ICan): React.ReactNode | null => {
    const permission = permissions.find((c) => c.path === path)
    if (!permission) return null
    if (typeof can === "string") {
      if (!permission.can?.includes(can)) return null
    } else {
      let hasPermission = false
      for (const _can of can) {
        if (permission.can?.includes(_can)) {
          hasPermission = true
          break
        }
      }
      if (!hasPermission) return null
    }
    return children
  }

  const can = ({ path, can }: ICanFunction): boolean => {
    const permission = permissions.find((c) => c.path === path)
    if (!permission) return false
    if (typeof can === "string") {
      if (!permission.can?.includes(can)) return false
    } else {
      let hasPermission = true
      for (const _can of can) {
        if (!permission.can?.includes(_can)) {
          hasPermission = false
          break
        }
      }
      if (!hasPermission) return false
    }
    return true
  }

  const canShowSubmenu = (uri: string[]): boolean => permissions.some((c) => uri.includes(c.path))

  function getPermissions(): TPermissions[] {
    try {
      const user: User = JSON.parse(localStorage.user)
      const _permissions = user.menu_parents
        .map((item) => {
          if (item.menu_type === "menu_parent") return item.items
          return item
        })
        .flat(1)
        .map((c): TPermissions => {
          const action = String(c?.description ?? "")
            .replace(/\s+/g, "")
            .toLowerCase()
          return {
            action,
            // @ts-ignore
            path: String(c.url).toLowerCase(),
            // @ts-ignore
            can: c.permissions.map((c) => c.name),
            name: c?.name,
            description: c?.description,
            id: c?.id,
          }
        })
      return _permissions
    } catch (error) {
      return []
    }
  }

  const hasPermissionList = (path: string): boolean => {
    const _permissions = getPermissions()
    const _module: TPermissions | undefined = _permissions.find((c) => c.path == path.toLowerCase())
    if (!_module) return false
    return _module.can.some((c) => c === "LIST")
  }

  const login = ({ user, token }: LoginProps) => {
    try {
      const newUser = { ...user }
      const newMenuParents = newUser.menu_parents.filter((item) => item.url != "/users-admins")
      newUser.menu_parents = newMenuParents
      if (!!newUser.company_id && [29, 30].includes(newUser.company_id)) {
        // @ts-ignore
        newUser.menu_parents = [...newMenuParents, ...gotoMenuParents]
      }
      localStorage.setItem(KEY_TOKEN, token)
      localStorage.user = JSON.stringify(newUser)
      const _permissions = getPermissions()
      routesWithPermissionsCreated.current = _permissions
      setPermissions(_permissions)
      setIsLoggedIn(true)
      setAuth(() => getUserFromLocalStorage())
    } catch (error) {}
  }

  const getAvailableMenus = (): string[] => {
    try {
      const menus = JSON.parse(localStorage.getItem(KEY_PATHS) ?? "[]")
      return menus
    } catch (error) {
      return []
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      const _menus = getAvailableMenus()
      setAvailableMenus(_menus)
      const __user__: User = getUserFromLocalStorage()
      setMenu(__user__.menu_parents)
    } else {
      setAvailableMenus([])
    }
  }, [isLoggedIn])

  const value = {
    permissions,
    canShowSubmenu,
    hasPermissionList,
    setIsLoggedIn,
    isLoggedIn,
    logout,
    login,
    setAvailableMenus,
    availableMenus,
    Can,
    CanSome,
    can,
    Auth,
    getAvailableMenus,
    setMenu,
    menu,
    getDataOfPage,
    routesWithPermissionsCreated,
  }

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
}

export default AuthenticationContextProvider
