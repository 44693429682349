interface IHasChangedPayload<T> {
  prev: T
  current: T
  watchParams?: (keyof T)[]
}

export const hasChanged = <T>({ prev, current, watchParams = [] }: IHasChangedPayload<T>) => {
  if (!!watchParams.length) {
    return watchParams.some((param) => prev[param] !== current[param])
  }
  // @ts-ignore
  return Object.keys(prev).some((key) => prev[key] !== current[key])
}

export const renderVersion = () => {
  console.log("%cVersion 1.0.1", "color: #ff6381;font-weight: bold")
}
