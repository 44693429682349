import { lazy } from "react"
import { Outlet, type RouteObject } from "react-router-dom"
import { AppLayout } from "@/layouts/AppLayout/AppLayout"
import { ProtectedAuthRoutes } from "@/router/ProtectedAuthRoutes"
import { PAGES } from "./routeInitial.state"
import { WrapperLoading } from "./WrapperLoading"
import LoadContextProvider from "@/pages/Loads/context/LoadContext"
import LoadsPage from "@/pages/Loads/LoadsPage"
import DispatchContextProvider from "@/pages/DispatchPage/context/DispatchContext"
import SocketContextProvider from "@/context/SocketContext"
import ColumnContextProvider from "@/context/ColumnContext"
import AppContextProvider from "@/context/AppContext"

const UserPage = lazy(() => import("@/pages/Users/UsersPage"))
const UsersAdminsPage = lazy(() => import("@/pages/UsersAdmins/UsersAdminsPage"))
const CompaniesPage = lazy(() => import("@/pages/Companies/CompaniesPage"))
const RolesPage = lazy(() => import("@/pages/Roles/RolesPage"))
const LoadCreatePage = lazy(() => import("@/pages/Loads/LoadCreatePage"))
const LoadEditPage = lazy(() => import("@/pages/Loads/LoadEditPage"))
const MenuOptionsPage = lazy(() => import("@pages/MenuOptions/MenuOptionsPage"))
const ClientsPage = lazy(() => import("@pages/Clients/ClientsPage"))
const DriversPage = lazy(() => import("@pages/Drivers/DriversPage"))
const TrailersPage = lazy(() => import("@pages/Trailers/TrailersPage"))
const TrucksPage = lazy(() => import("@pages/Trucks/TrucksPage"))
const MiscellaneousPage = lazy(() => import("@pages/Miscellaneous/MiscellaneousPage"))
const TerminalsPage = lazy(() => import("@pages/Terminals/TerminalsPage"))
const EQClassesPage = lazy(() => import("@/pages/EQClasses/EQClassesPage"))
const StatusPage = lazy(() => import("@/pages/Status/StatusPage"))
const ZonesPage = lazy(() => import("@/pages/Zones/ZonesPage"))
const CarriersPage = lazy(() => import("@/pages/Carriers/CarriersPage"))
const CommoditiesPage = lazy(() => import("@/pages/Commodities/CommoditiesPage"))
const ServiceLevelPage = lazy(() => import("@/pages/ServiceLevel/ServiceLevelPage"))
const TypeAcchargesPage = lazy(() => import("@/pages/TypeAccharges/TypeAcchargesPage"))
const TypeTracesPage = lazy(() => import("@/pages/TypeTraces/TypeTracesPage"))
const DispatchPage = lazy(() => import("@/pages/DispatchPage/DispatchPage"))
const DoorsPage = lazy(() => import("@/pages/Doors/DoorsPage"))
const ApiFieldOverridePage = lazy(() => import("@/pages/ApiFieldOverrides/ApiFieldOverridePage"))
const MessageCenterPage = lazy(() => import("@/pages/MessageCenter/MessageCenterPage"))
const FormMastersPage = lazy(() => import("@/pages/FormMasters/FormMastersPage"))
const FormFieldGroupPage = lazy(() => import("@/pages/FormFieldGroup/FormFieldGroupPage"))
const FormActionPage = lazy(() => import("@/pages/FormAction/FormActionPage"))
const FormButtonTypePage = lazy(() => import("@/pages/FormButtonType/FormButtonTypePage"))
const FormUserDatatypeDefinirPage = lazy(() => import("@/pages/FormUserDatatypeDefiner/FormUserDatatypeDefinerPage"))
const FormMasterTypesPage = lazy(() => import("@/pages/FormMasterTypes/FormMasterTypesPage"))
const TablesPage = lazy(() => import("@/pages/Tables/TablesPage"))
const CrudActionsPage = lazy(() => import("@/pages/CrudActions/CrudActionsPage"))
const DriverRatesPage = lazy(() => import("@/pages/DriverRates/DriverRatesPage"))
const SurveysPage = lazy(() => import("@/pages/Surveys/SurveysPage"))
const MenuOptionsParentsPage = lazy(() => import("@/pages/MenuOptionsParents/MenuOptionsParentsPage"))
const GenericPage = lazy(() => import("@/pages/Generic/GenericPage"))
const InspectionsPage = lazy(() => import("@/pages/Inspections/InspectionsPage"))
const DispatchMapPage = lazy(() => import("@/pages/DispatchMap/DispatchMapPage"))
const ClockInForAllPage = lazy(() => import("@/pages/ClockInForAll/ClockInForAllPage"))
const LTLMilesPage = lazy(() => import("@/pages/LTLMiles/LTLMilesPage"))
const DriverPayPage = lazy(() => import("@/pages/DriverPay/DriverPayPage"))

export const AppMainRoutes: RouteObject = {
  path: "/",
  element: (
    <ProtectedAuthRoutes>
      <SocketContextProvider>
        <AppContextProvider>
          <ColumnContextProvider>
            <AppLayout />
          </ColumnContextProvider>
        </AppContextProvider>
      </SocketContextProvider>
    </ProtectedAuthRoutes>
  ),
  children: [],
}

export const AppRoutes: RouteObject[] = [
  {
    id: PAGES.USERS_ADMINS.id,
    path: PAGES.USERS_ADMINS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <UsersAdminsPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.USERS_ADMINS.id,
    path: PAGES.USERS_ADMINS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <UsersAdminsPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.USERS.id,
    path: PAGES.USERS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <UserPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.CLIENTS.id,
    path: PAGES.CLIENTS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <ClientsPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.COMPANIES.id,
    path: PAGES.COMPANIES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <CompaniesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.ROLES.id,
    path: PAGES.ROLES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <RolesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.LOADS.id,
    path: PAGES.LOADS.path,
    element: (
      <WrapperLoading>
        <LoadContextProvider>
          <div className="p-1">
            <Outlet />
          </div>
        </LoadContextProvider>
      </WrapperLoading>
    ),
    children: [
      {
        index: true,
        element: <LoadsPage />,
      },
      {
        id: PAGES.LOADS.id,
        path: `${PAGES.LOADS.path}/create`,
        element: <LoadCreatePage />,
      },
      {
        id: PAGES.LOADS.id,
        path: `${PAGES.LOADS.path}/edit/:id`,
        element: <LoadEditPage />,
      },
    ],
  },
  {
    id: PAGES.MENU_OPTIONS.id,
    path: PAGES.MENU_OPTIONS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <MenuOptionsPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.DRIVERS.id,
    path: PAGES.DRIVERS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <DriversPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.DRIVER_RATES.id,
    path: PAGES.DRIVER_RATES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <DriverRatesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.TRAILERS.id,
    path: PAGES.TRAILERS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <TrailersPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.TRUCKS.id,
    path: PAGES.TRUCKS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <TrucksPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.MISCELLANEOUS.id,
    path: PAGES.MISCELLANEOUS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <MiscellaneousPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.TERMINALS.id,
    path: PAGES.TERMINALS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <TerminalsPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.EQ_CLASSES.id,
    path: PAGES.EQ_CLASSES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <EQClassesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.STATUS.id,
    path: PAGES.STATUS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <StatusPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.ZONES.id,
    path: PAGES.ZONES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <ZonesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.CARRIERS.id,
    path: PAGES.CARRIERS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <CarriersPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.COMMODITIES.id,
    path: PAGES.COMMODITIES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <CommoditiesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.SERVICE_LEVEL.id,
    path: PAGES.SERVICE_LEVEL.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <ServiceLevelPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.TYPE_ACCHARGES.id,
    path: PAGES.TYPE_ACCHARGES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <TypeAcchargesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.TYPE_TRACES.id,
    path: PAGES.TYPE_TRACES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <TypeTracesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.DISPATCH.id,
    path: PAGES.DISPATCH.path,
    element: (
      <WrapperLoading>
        <DispatchContextProvider>
          <div className="p-1">
            <DispatchPage />
          </div>
        </DispatchContextProvider>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.DOORS.id,
    path: PAGES.DOORS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <DoorsPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.API_FIELD_OVERRIDE.id,
    path: PAGES.API_FIELD_OVERRIDE.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <ApiFieldOverridePage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.MESSAGE_CENTER.id,
    path: PAGES.MESSAGE_CENTER.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <MessageCenterPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.FORM_MASTERS.id,
    path: PAGES.FORM_MASTERS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <FormMastersPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.FORM_FIELD_GROUP.id,
    path: PAGES.FORM_FIELD_GROUP.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <FormFieldGroupPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.FORM_ACTION.id,
    path: PAGES.FORM_ACTION.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <FormActionPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.FORM_BUTTON_TYPE.id,
    path: PAGES.FORM_BUTTON_TYPE.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <FormButtonTypePage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.FORM_USER_DATATYPE_DEFINER.id,
    path: PAGES.FORM_USER_DATATYPE_DEFINER.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <FormUserDatatypeDefinirPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.FORM_MASTER_TYPES.id,
    path: PAGES.FORM_MASTER_TYPES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <FormMasterTypesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.CRUD_TABLES.id,
    path: PAGES.CRUD_TABLES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <TablesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.CRUD_ACTIONS.id,
    path: PAGES.CRUD_ACTIONS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <CrudActionsPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.SURVEYS.id,
    path: PAGES.SURVEYS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <SurveysPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.MENU_OPTIONS_PARENTS.id,
    path: PAGES.MENU_OPTIONS_PARENTS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <MenuOptionsParentsPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.INSPECTIONS.id,
    path: PAGES.INSPECTIONS.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <InspectionsPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.DISPATCH_MAP.id,
    path: PAGES.DISPATCH_MAP.path,
    element: (
      <WrapperLoading>
        <DispatchContextProvider>
          <DispatchMapPage />
        </DispatchContextProvider>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.CLOCK_IN_FOR_ALL.id,
    path: PAGES.CLOCK_IN_FOR_ALL.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <ClockInForAllPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.LTL_MILES.id,
    path: PAGES.LTL_MILES.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <LTLMilesPage />
        </div>
      </WrapperLoading>
    ),
  },
  {
    id: PAGES.DRIVER_PAY.id,
    path: PAGES.DRIVER_PAY.path,
    element: (
      <WrapperLoading>
        <div className="p-1">
          <DriverPayPage />
        </div>
      </WrapperLoading>
    ),
  },
]

export const getAppRoute = (path: string): RouteObject | undefined => {
  const found = AppRoutes.find((c) => c.path?.endsWith(path))
  if (found) return found
  const newDynamicRoute = {
    id: path,
    path: path,
    element: (
      <WrapperLoading>
        <GenericPage />
      </WrapperLoading>
    ),
  }
  return newDynamicRoute
}
