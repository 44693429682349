import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { useEffect } from "react"

import LayoutContextProvider from "@/context/AppAuthContext"
import { AppLayoutHeader } from "./AppLayoutHeader"
import { AppLayoutAsideNew } from "./AppLayoutAsideNew"
import { renderVersion } from "@/utils"

import "react-toastify/dist/ReactToastify.css"

export const AppLayout = (): JSX.Element => {
  useEffect(() => {
    renderVersion()
  }, [])

  return (
    <LayoutContextProvider>
      <div className="flex text-slate-600 dark:text-slate-300 dark:bg-gray-900">
        <AppLayoutAsideNew>
          <main className="w-full max-h-screen min-h-screen overflow-auto">
            <AppLayoutHeader />
            <Outlet />
          </main>
        </AppLayoutAsideNew>
      </div>
      <ToastContainer />
    </LayoutContextProvider>
  )
}
