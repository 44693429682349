import { useContext } from "react"
import { Dropdown } from "./Dropdown"
import { TLocale, TranslateContext } from "@/context/TranslateContext"

const langs = [
  {
    img: "/images/en.png",
    name: "EN",
  },
  {
    img: "/images/es.png",
    name: "ES",
  },
]

interface DropdownLocaleProps {
  direction?: "start" | "end"
}

export const DropdownLocale = ({ direction = "start" }: DropdownLocaleProps) => {
  const { locale, changeLocale } = useContext(TranslateContext)
  return (
    <Dropdown
      direction={direction}
      renderLabel={
        <span className="text-xs flex h-full gap-1 items-center uppercase">
          <img src={locale === "EN" ? "/images/en.png" : "/images/es.png"} className="h-5 w-5 rounded-full" alt="Flag" /> {locale}
        </span>
      }
    >
      {langs
        .filter((c) => c.name !== locale)
        .map((lang, index) => (
          <Dropdown.Item key={index} onClick={() => changeLocale(lang.name as TLocale)}>
            <div className="flex h-full gap-1.5 items-center px-2">
              <img src={lang.img} className="h-5 w-5 rounded-full" alt={`Flag ${lang.name}`} />
              <span>{lang.name}</span>
            </div>
          </Dropdown.Item>
        ))}
    </Dropdown>
  )
}
