import React, { useContext, useDeferredValue, useState } from "react"
import { useNavigate } from "react-router-dom"
import { TbSearch } from "react-icons/tb"
import { useIntl } from "react-intl"
import clsx from "clsx"

import type { TVisitedPage } from "@/types/App"
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses, MenuItemStyles } from "@core/ProSidebar"
import { SidebarHeader } from "@core/ProSidebar/components/SidebarHeader"
import IconProSidebar from "@core/ProSidebar/components/IconProSidebar"
import { LayoutContext } from "@/context/AppAuthContext"
import { AuthenticationContext } from "@/context/AuthenticateContext"
import { SuperInput } from "@/@core"
import SuspenseFallback from "@/@core/SuspenseFallback"
import { AppContext } from "@/context/AppContext"

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#041731",
      color: "#607489",
    },
    menu: {
      menuContent: "#000a17",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#172554",
        color: "#ffffff",
      },
      disabled: {
        color: "#5b81aa",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#041731",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#000a17",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#172554",
        color: "#ffffff",
      },
      disabled: {
        color: "#5b81aa",
      },
    },
  },
}

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const AppLayoutAsideNew = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const { formatMessage: $t } = useIntl()
  const { setToggled, setBroken, collapsed, toggled, rtl, hasImage, theme, onCloseMenuMobile } = useContext(LayoutContext)!
  const { menu, can, routesWithPermissionsCreated } = useContext(AuthenticationContext)!
  const { dispatchApp, iconIndexed } = useContext(AppContext)
  const [query, setQuery] = useState<string>("")
  const deferredQuery = useDeferredValue(query)
  const navigate = useNavigate()

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: level === 0 ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1) : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  }

  const onRedirect = (payload: TVisitedPage) => {
    const { pathname } = payload
    dispatchApp({ type: "ADD_PAGE_TO_VISITED_PAGES", payload })
    navigate(pathname)
  }

  return (
    <div className="flex h-screen max-h-screen w-screen">
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        rtl={rtl}
        breakPoint="md"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
        rootStyles={{ color: themes[theme].sidebar.color }}
      >
        <div className="bg-[#041731] flex flex-col h-full text-white">
          <SidebarHeader collapsed={collapsed} />
          <div className={clsx("h-10 px-4", collapsed && "hidden")}>
            <SuperInput
              className="!bg-white"
              value={query}
              placeholder={$t({ id: "Search" })}
              IconEnd={TbSearch}
              onChange={(ev) => setQuery(ev.target.value)}
            />
          </div>
          <div className="flex-1 pt-3">
            <Menu menuItemStyles={menuItemStyles}>
              {query.length ? (
                <>
                  <SuspenseFallback>
                    {routesWithPermissionsCreated.current
                      .filter((c) => c.name?.toLocaleLowerCase().includes(deferredQuery.toLocaleLowerCase()))
                      .map(
                        (_menu_, index) =>
                          can({ path: _menu_.path!, can: ["LIST", "SHOW"] }) && (
                            <MenuItem
                              key={index}
                              icon={
                                <IconProSidebar>
                                  <i className={iconIndexed.get(_menu_.id!) || "fa-solid fa-chevron-right"}></i>
                                </IconProSidebar>
                              }
                              className="capitalize"
                              onClick={() => {
                                setQuery("")
                                onCloseMenuMobile()
                                onRedirect({ title: _menu_.name!, pathname: _menu_.path! })
                              }}
                            >
                              {_menu_.name}
                            </MenuItem>
                          ),
                      )}
                  </SuspenseFallback>
                </>
              ) : (
                menu.map((_menu_, index) =>
                  _menu_.menu_type === "menu_url" ? (
                    can({ path: _menu_.url!, can: ["LIST", "SHOW"] }) && (
                      <MenuItem
                        key={index}
                        icon={
                          <IconProSidebar>
                            <i className={iconIndexed.get(_menu_.id!) || "fa-solid fa-chevron-right"}></i>
                          </IconProSidebar>
                        }
                        className="capitalize"
                        onClick={() => {
                          onCloseMenuMobile()
                          onRedirect({ title: _menu_.name!, pathname: _menu_.url! })
                        }}
                      >
                        {_menu_.name}
                      </MenuItem>
                    )
                  ) : (
                    <SubMenu
                      key={index}
                      label={_menu_.name}
                      icon={
                        <IconProSidebar>
                          <i className={iconIndexed.get(_menu_.id!) || "fa-solid fa-chevron-right"}></i>
                        </IconProSidebar>
                      }
                    >
                      {_menu_.items?.map(
                        (submenu, submenuIndex) =>
                          can({ path: submenu.url!, can: ["LIST", "SHOW"] }) && (
                            <MenuItem
                              key={submenuIndex}
                              onClick={() => {
                                onCloseMenuMobile()
                                onRedirect({ title: submenu.name, pathname: submenu.url! })
                              }}
                            >
                              {submenu.name}
                            </MenuItem>
                          ),
                      )}
                    </SubMenu>
                  ),
                )
              )}
            </Menu>
          </div>
        </div>
      </Sidebar>
      {children}
    </div>
  )
}
